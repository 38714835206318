import React from "react";
import { Td, TableList } from "static/coreStyles/table.styled";
import { Avatar } from "antd";
import { Theme } from "static/coreStyles/theme.styled";
import { getInitials } from "utility/helperFunctions";
import { formatToDateString } from "utility/helperFunctions";

export const GroupList = ({
  data: {
    id,
    groupId,
    name,
    foundingPlace,
    admins,
    foundingYear,
    groupPicture,
    memberCount,
    totalKm,
    totalRides,
    createdAt,
    isInvite,
    isSecret,
    isPrivate,
  },
}) => {
  if (!id) return null;

  return (
    <TableList>
      <Td width="18%">
        <div style={{ display: "flex", paddingRight: 10 }}>
          <div className="info" style={{ display: "flex", marginRight: 10 }}>
            {groupPicture ? (
              <Avatar size={27} src={groupPicture} alt="grouppicture" />
            ) : (
              <Avatar style={{ backgroundColor: Theme.blueColor }} size={27}>
                {getInitials(name)}
              </Avatar>
            )}
          </div>
          <div>
            {name && <span>{name}</span>}
            {groupId && <em>{groupId}</em>}
          </div>
        </div>
      </Td>
      <Td width="12%">
        <div className="info">
          <div>
            {Array.isArray(admins) && admins.length > 0 && (
              <span>
                {String(admins[0].firstName + " " + admins[0].lastName).trim()}
              </span>
            )}
            {Array.isArray(admins) && admins.length > 0 && (
              <em>{String(admins[0].userName).trim()}</em>
            )}
          </div>
        </div>
      </Td>
      <Td width="12%">
        <div className="info">
          <div>
            {Array.isArray(admins) && admins.length > 0 && (
              <span>
                {"+"}
                {String(admins[0].countryCode).trim()}{" "}
                {String(admins[0].phoneNumber).trim()}
              </span>
            )}
          </div>
        </div>
      </Td>
      <Td width="12%">
        {foundingPlace && <span>{foundingPlace.displayName}</span>}
      </Td>
      <Td width="14%">
        {createdAt && <span>{formatToDateString(createdAt)}</span>}
      </Td>
      {/* <Td width="6%">{foundingYear && <span>{foundingYear}</span>}</Td> */}

      <Td width="8%">{memberCount && <span>{memberCount}</span>}</Td>
      <Td width="8%">{totalKm && <span>{totalKm}</span>}</Td>
      <Td width="8%">{totalRides && <span>{totalRides}</span>}</Td>
      <Td width="8%">
        <span>{isPrivate ? "🔒 Private" : "✅ Public"}</span>
      </Td>
    </TableList>
  );
};
