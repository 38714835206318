import React, {useState} from 'react';
import RootRouter from "../src/router/rootRouter";
// import "antd/dist/antd.css";
import './App.css';
import Header from 'components/Header';

function useMyTodoList() {
  // const newState = 0;

  return {

  };
}


function App() {
  const [inputValue, setInputValue] = useState('');
  const [todoList, setTodoList] = useState([]);

  return (
    <div className="App">
       <Header />
       <RootRouter />
    </div>
  );
}

export default App;
