import RideAPI from "services/ride";
import swalState from "utility/swal";
import { message } from "antd";
import { removeEmptyValues } from "utility/helperFunctions";

function handleError(err) {
  console.error("Error in users actions: ", err);
}

export function setRideList(payload) {
  return {
    type: "SET_RIDE_LIST",
    payload,
  };
}
export function updateRideFilter(payload) {
  return {
    type: "UPDATE_RIDE_FILTER",
    payload,
  };
}

export function setRideFilter(payload) {
  return {
    type: "SET_RIDE_FILTER",
    payload,
  };
}


export const getRideList = (filters) => async (dispatch) => {
  try {
    swalState.showLoader();
    let response = await RideAPI.getRideList(removeEmptyValues(filters));
    if (response) {
      const { data, count, totalCount, prev, next, pageSize } = response;
      swalState.hideLoader();
      dispatch(setRideList({
        data,
        pagination: {
          count,
          totalCount,
          prev,
          next,
          pageSize,
        },
      }));
    }
  } catch (err) {
    message.error(err.message);
    handleError(err);
    swalState.hideLoader();
  }
};

export const updateFilters = (update) => async (dispatch) => {
  try {
    dispatch(updateRideFilter(update));
  } catch (err) {
    handleError(err);
  }
};

export const setFilters = (update) => async (dispatch) => {
  try {
    dispatch(setRideFilter(update));
  } catch (err) {
    handleError(err);
  }
};
