import { Api } from "../config/request";


export const getGroupList = (payload) => {
  return Api.postRequest(`admins/groups`, payload);
};

const GroupAPI = {
  getGroupList
};

export default GroupAPI;
