import Avatar from "antd/lib/avatar/avatar";
import React from "react";
import { Td, TableList, Status } from "static/coreStyles/table.styled";
import { Theme } from "static/coreStyles/theme.styled";
import { getInitials } from "utility/helperFunctions";
import { formatToDateString } from "utility/helperFunctions";

export const RideList = ({
  data: {
    id,
    type,
    association,
    group,
    headedTo,
    startFrom,
    name,
    dayType,
    startDateTime,
    endDateTime,
    totalDistance,
    riderCount,
    rideMembers,
    limitRiders,
    allowedRiderCount,
    rideCharge,
    rideStatus,
    createdAt,
    isPaid,
    isRidePublic,
    isWomenOnly,
  },
}) => {
  if (!id) return null;
  return (
    <TableList>
      <Td width="15%">
        <div style={{ display: "flex" }}>
          <Avatar
            size={27}
            style={{ backgroundColor: Theme.blueColor, marginRight: 10 }}
          >
            {rideMembers && rideMembers.length > 0
              ? getInitials(rideMembers[0].firstName, rideMembers[0].lastName)
              : ""}
          </Avatar>
          <div>
            {rideMembers && rideMembers.length > 0 && (
              <span>
                {String(
                  rideMembers[0].firstName + " " + rideMembers[0].lastName
                ).trim()}
              </span>
            )}
            {group && <em>{group.name}</em>}
          </div>
        </div>
      </Td>
      <Td width="15%">{name && <span>{name}</span>}</Td>
      <Td width="15%">
        {createdAt && <span>{formatToDateString(createdAt)}</span>}
      </Td>
      <Td width="15%">
        {startDateTime && <span>{formatToDateString(startDateTime)}</span>}
      </Td>
      <Td width="15%">
        {startDateTime && (
          <span style={{ display: "flex" }}>
            {/* {formatToDateString(startDateTime)} →{" "}
            {formatToDateString(endDateTime)} */}
            <span style={{ marginRight: 6 }}>
              {startFrom && startFrom.displayName
                ? String(startFrom.displayName).trim()
                : ""}
            </span>
            <span>→</span>
            <span style={{ marginLeft: 6 }}>
              {headedTo && headedTo.displayName
                ? String(headedTo.displayName).trim()
                : ""}
            </span>
          </span>
        )}
      </Td>
      <Td width="5%">{riderCount && <span>{riderCount}</span>}</Td>
      <Td width="5%">
        <span>{allowedRiderCount ? allowedRiderCount : ""}</span>
      </Td>
      <Td width="9%">
        {isPaid && rideCharge ? (
          rideCharge.amount > 0 ? (
            <span>₹{rideCharge.amount}</span>
          ) : (
            <span>Split</span>
          )
        ) : (
          <span>Free</span>
        )}
      </Td>
      <Td width="11%">
        <Status status={rideStatus}>{rideStatus}</Status>
      </Td>
      <Td width="10%">
        <span>{isRidePublic ? "✅ Public" : "🔒 Private"}</span>
      </Td>
    </TableList>
  );
};
