import styled from "styled-components";
import { Font, Theme } from "static/coreStyles/theme.styled";

export const Container = styled.div`
  max-width: 1130px;
  margin: 0 auto;
  padding: 0 40px;
  width: 100%;
`;

export const FlexBox = styled.div`
  display: flex;
  align-items: ${(props) =>
    props.start ? "flex-start" : props.end ? "flex-end" : "center"};
  justify-content: ${(props) =>
    props.between ? "space-between" : props.start ? "flex-start" : "center"};
  flex-direction: ${(props) => (props.column ? "column" : "row")};
  &.metric-cell {
    align-items: flex-start;
    span {
      color: red;
      cursor: pointer;
    }
  }
`;
export const PageHeader = styled.div``;
export const TitleHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const FilterWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const Form = styled.form`
  width: 100%;
`;

export const FormItem = styled.div`
  margin-bottom: 30px;
  position: relative;
  label {
    color: ${Theme.greyColor};
    display: block;
    margin-bottom: 10px;
  }
  input {
    height: 46px;
    border-radius: 4px;
    background-color: #f4f5f7;
    width: 100%;
    color: ${Theme.primaryColor};
    padding: 0 15px;
  }
`;

export const ButtonWrap = styled.div`
  display: flex;
  justify-content: space-between;
  div {
    cursor: pointer;
  }
  button {
    margin-right: 12px;
    :last-child {
      margin-right: 0;
    }
  }
  &.login-btn-wrap {
    margin-top: 70px;
  }
`;

export const Button = styled.button`
  background-color: ${({ primary }) =>
    primary ? Theme.blueColor : Theme.white};
  font-family: ${Font.bold};
  color: ${({ primary }) => (primary ? Theme.white : Theme.blueColor)};
  min-width: 120px;
  padding: 12px 15px;
  font-size: 14px;
  border-radius: 6px;
`;

export const SmallButton = styled.button`
  border: 2px solid
    ${(props) =>
      props.primaryOutline ? Theme.blueColor : "rgba(112, 112, 112, 0.3)"};
  background-color: ${(props) =>
    props.primary ? Theme.blueColor : Theme.white};
  font-family: ${Font.bold};
  color: ${Theme.white};
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 44px;
  min-height: 44px;
  border-radius: 6px;
`;

export const TagWrap = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  span {
    font-family: ${Font.bold};
    color: rgba(112, 112, 112);
  }
`;

export const SearchContainer = styled.div`
  display: flex;
`;
export const SearchWrap = styled.div`
  position: relative;
  margin-right: 12px;
  input {
    width: 268px;
    height: 44px;
    border-radius: 6px;
    border: solid 2px rgba(112, 112, 112, 0.3);
    background-color: #ffffff;
    padding: 0 33px;
    color: ${Theme.greyColor};
    font-size: 14px;
    font-family: ${Font.bold};
    font-weight: 700;
  }
  img {
    position: absolute;
    top: 16px;
    left: 16px;
  }
`;

export const PageWrap = styled.div`
  > div {
    min-height: calc(100vh - 95px);
    position: relative;
    padding-bottom: 90px;
  }
`;

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
`;

export const Filter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
  margin: 11px 0 11px 0;
  :first-child {
    margin-top: 0;
  }
  :last-child {
    margin-bottom: 0;
  }
`;

export const FilterTitle = styled.div`
  font-family: ${Font.bold};
  color: ${({ primary }) => (primary ? Theme.blueColor : Theme.black)};
  font-size: 14px;
  flex: 1;
`;

export const FilterItem = styled.div`
  display: flex;
  flex: 1;
`;
