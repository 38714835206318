export const tableHead = [
  {
    title: "Group Name",
    width: "18%",
    sortKey: "name",
  },
  {
    title: "Admin",
    width: "12%",
  },
  {
    title: "Number",
    width: "12%",
  },
  {
    title: "Based in",
    width: "12%",
  },
  {
    title: "Date Created",
    sortKey: "createdAt",
    width: "14%",
  },
  // {
  //   title: "Founding Year",
  //   width: '6%',
  // },
  {
    title: "Members",
    sortKey: "members",
    width: "8%",
  },
  {
    title: "Distance",
    sortKey: "distance",
    width: "8%",
  },
  {
    title: "Rides",
    sortKey: "rides",
    width: "8%",
  },
  {
    title: "Public",
    width: "8%",
  },
];

export const userList = [
  {
    groupName: "Highway Men",
    groupId: "group_id",
    adminName: "Lia Castro",
    adminOrg: "Lia_Castro_Rider",
    basedIn: "New Delhi",
    startDate: "27 Jul’ 20",
    Members: "21",
    Distance: "4000 kms",
    rides: "12",
    Public: "Public",
  },
  {
    groupName: "Highway Men",
    groupId: "group_id",
    adminName: "Lia Castro",
    adminOrg: "Lia_Castro_Rider",
    basedIn: "New Delhi",
    startDate: "27 Jul’ 20",
    Members: "21",
    Distance: "4000 km",
    rides: "12",
    Public: "Public",
  },
  {
    groupName: "Highway Men",
    groupId: "group_id",
    adminName: "Lia Castro",
    adminOrg: "Lia_Castro_Rider",
    basedIn: "New Delhi",
    startDate: "27 Jul’ 20",
    Members: "21",
    Distance: "4000 km",
    rides: "12",
    Public: "Public",
  },
];
